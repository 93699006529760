import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

function fetchConsultationDetail(data) {
  const url = 'second-opinion/v1/request-consultation/' + data.id + '/';
  return (dispatch) => {
    authRequest(url, 'GET', null, data.token)
      .then((res) => {
        let profile = {
          response: res.data.data,
          tempMedicineConditions: data.tempMedicineConditions,
          tempImmunizations: data.tempImmunizations,
          tempSurgeries: data.tempSurgeries,
          temp_lifestyle_category: data.temp_lifestyle_category,
        };
        dispatch(fetchConsultationDetailSuccess(profile));
      })
      .catch((error) => {
        dispatch(fetchConsultationDetailFailure(error));
      });
  };
}

export const fetchStaticConsultationDetailData = (request) => {
  const url = 'utils/v1/pre-populated-data/';

  return (dispatch) => {
    dispatch(fetchConsultationDetailRequest());
    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let response = res.data;
        let tempMedicineConditions = {};
        response.data.medical_conditions.forEach((element) => {
          tempMedicineConditions[element.id] = element.name;
        });

        let tempImmunizations = [];

        response.data.immunizations.forEach((element) => {
          tempImmunizations[element.id] = element.name;
        });

        let tempSurgeries = [];

        response.data.surgeries.forEach((element) => {
          tempSurgeries[element.id] = element.name;
        });
        let temp_lifestyle_category = [];
        response.data.lifestyle_category.forEach((element) => {
          temp_lifestyle_category[element.id] = element;
        });
        let data = {
          tempMedicineConditions: tempMedicineConditions,
          tempImmunizations: tempImmunizations,
          tempSurgeries: tempSurgeries,
          temp_lifestyle_category: temp_lifestyle_category,
          token: request.token,
          id: request.id,
        };
        dispatch(fetchConsultationDetail(data));
      })

      .catch((error) => {
        console.error(error);
        dispatch(fetchConsultationDetailFailure(error));
      });
  };
};

export const fetchConsultationDetailRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchConsultationDetailSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchConsultationDetailFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
