export * from './static';
export * from './consultation';
export * from './documents';
export * from './medicalprofile';
export * from './profile';
export * from './physician';
export * from './notifications';
export * from './accounts';
export * from './message';
export * from './specialities';

export const signUp = () => ({
  type: 'SIGN_IN',
});

export const signOut = () => ({
  type: 'SIGN_OUT',
});

export const updateToken = (token) => ({
  type: 'UPDATE_TOKEN',
  payload: token,
});

export const updateAccessToken = (token) => ({
  type: 'UPDATE_ACCESS_TOKEN',
  payload: token,
});

export const updateUser = (user) => ({
  type: 'UPDATE_USER',
  payload: user,
});
