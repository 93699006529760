import * as storage from '../../utilities/localStorageUtility';

let logged = storage.getIsLogged() !== null ? storage.getIsLogged() : false;
logged = String(logged) === 'true';

const LoggedReducer = (state = logged, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return true;
    case 'SIGN_OUT':
      return false;
    default:
      return state;
  }
};

export default LoggedReducer;
