export const setOpinionRequestBoolean = (value) => {
  sessionStorage.setItem('opinionRequestPage', value);
};

export const setUser = (value) => {
  sessionStorage.setItem('user', value);
};

export const setToken = (value) => {
  sessionStorage.setItem('token', value);
};

export const setAccessToken = (value) => {
  sessionStorage.setItem('accessToken', value);
};

export const setIsLogged = (value) => {
  sessionStorage.setItem('isLogged', value);
};

export const setSelectedDoctor = (value) => {
  sessionStorage.setItem('selectedDoctor', value);
};

export const setConsultationDetails = (value) => {
  sessionStorage.setItem('consultationDetails', JSON.stringify(value));
};

export const setAppliedCouponCode = (value) => {
  sessionStorage.setItem('couponCode', value);
};

export const setAppliedCouponData = (value) => {
  sessionStorage.setItem('appliedCouponData', JSON.stringify(value));
};

export const getOpinionRequestBoolean = () => sessionStorage.getItem('opinionRequestPage');
export const getUser = () => sessionStorage.getItem('user');
export const getToken = () => sessionStorage.getItem('token');
export const getAccessToken = () => sessionStorage.getItem('accessToken');
export const getIsLogged = () => sessionStorage.getItem('isLogged');
export const getSelectedDoctor = () => sessionStorage.getItem('selectedDoctor');
export const getConsultationDetails = () => JSON.parse(sessionStorage.getItem('consultationDetails'));
export const getAppliedCouponCode = () => sessionStorage.getItem('couponCode');
export const getAppliedCouponData = () => JSON.parse(sessionStorage.getItem('appliedCouponData'));

export default setOpinionRequestBoolean;
