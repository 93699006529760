import { FETCH_SPECIALITIES_REQUEST, FETCH_SPECIALITIES_SUCCESS, FETCH_SPECIALITIES_FAILURE } from './constants';

const initialState = {
  specialitiesLoading: false,
  specialitiesResult: [],
  specialitiesError: '',
};

const specialitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SPECIALITIES_REQUEST:
      return {
        ...state,
        specialitiesLoading: true,
      };
    case FETCH_SPECIALITIES_SUCCESS:
      return {
        specialitiesLoading: false,
        specialitiesResult: action.payload,
        specialitiesError: '',
      };
    case FETCH_SPECIALITIES_FAILURE:
      return {
        specialitiesLoading: false,
        specialitiesResult: [],
        specialitiesError: action.payload,
      };
    default:
      return state;
  }
};

export default specialitiesReducer;
