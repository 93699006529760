import React from 'react';
import LandingPageHeader from '../components/LandingPageHeader';
import LandingPageFooter from '../components/LandingPageFooter';
import { Box, Grid } from '@material-ui/core';

const PageLayout = ({ children }) => (
  <Grid xs={12} display='flex' container justifyContent='center' alignItems='center'>
    <LandingPageHeader />
    <Box mt={4}></Box>
    {children}
    <LandingPageFooter />
  </Grid>
);

export default PageLayout;
