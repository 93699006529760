import LoggedReducer from './isLogged';
import TokenReducer from './Token';
import AccessTokenReducer from './accessToken';
import UserReducer from './User';
import reducer from './ConsultationReducer';
import medreducer from './medicalReducer';
import notificationReducer from './NotificationsReducer';
import imagingReducer from './ImagingStudies';
import labReducer from './LabStudies';
import otherReducer from './OtherDocuments';
import submittedReducer from './SubmittedReducer';

import messagesReducer from './MessagesReducer';

import specialitiesReducer from './specialitiesReducer';

import urlReducer from './urlReducer';

import { combineReducers } from 'redux';

const allReducers = combineReducers({
  isLogged: LoggedReducer,
  token: TokenReducer,
  accessToken: AccessTokenReducer,
  user: UserReducer,
  result: reducer,
  medresult: medreducer,
  notificationResult: notificationReducer,
  imagingReducer: imagingReducer,
  labReducer: labReducer,
  otherReducer: otherReducer,
  submittedReducer: submittedReducer,
  messagesReducer: messagesReducer,
  urlReducer: urlReducer,
  specialitiesReducer: specialitiesReducer,
});

export default allReducers;
