import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

const url = 'user/v1/profile/';
export const ProfilePost = (token) => {
  return (dispatch) => {
    dispatch(fetchProfilePostRequest());
    let data = { dummy: 'data' };
    authRequest(url, 'POST', data, token)
      .then((res) => {
        dispatch(fetchProfilePostSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchProfilePostFailure(error.message));
      });
  };
};

export const fetchProfilePostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchProfilePostSuccess = (consultation) => {
  return {
    type: FETCH_SUCCESS,
    payload: consultation,
  };
};

export const fetchProfilePostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
