import { FETCH_OTHER_REQUEST, FETCH_OTHER_SUCCESS, FETCH_OTHER_FAILURE } from './constants';

const initialState = {
  loading: false,
  result: [],
  error: '',
};

const otherReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OTHER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_OTHER_SUCCESS:
      return {
        loading: false,
        result: action.payload,
        error: '',
      };
    case FETCH_OTHER_FAILURE:
      return {
        loading: false,
        result: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default otherReducer;
