import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const individualRequest = (request) => {
  const url = `user/v1/profile-request/${request.id}/`;
  return (dispatch) => {
    dispatch(fetchIndividualRequest());
    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        dispatch(fetchIndividualSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchIndividualFailure(error.message));
      });
  };
};

export const fetchIndividualRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchIndividualSuccess = (profile) => {
  return {
    type: FETCH_SUCCESS,
    payload: profile,
  };
};

export const fetchIndividualFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
