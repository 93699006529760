import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

function fetchConsultationNotes(request) {
  const url = 'second-opinion/v1/notes/';
  let data = { notes: request.notes };
  return (dispatch) => {
    authRequest(url, 'POST', data, request.token)
      .then((res) => {
        dispatch(fetchConsultationDetailPostSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchConsultationDetailPostFailure(error));
      });
  };
}

export const fetchStaticConsultationDetailDataPost = (request) => {
  const url = 'second-opinion/v1/request-consultation/' + request.id + '/';

  return (dispatch) => {
    dispatch(fetchConsultationDetailPostRequest());
    authRequest(url, 'PATCH', request.data, request.token)
      .then((res) => {
        let response = res.data.data;
        let notesResponse = { token: request.token, notes: request.notes };
        if (request.notes.length > 0) {
          dispatch(fetchConsultationNotes(notesResponse));
        } else {
          dispatch(fetchConsultationDetailPostSuccess(response));
        }
      })

      .catch((error) => {
        dispatch(fetchConsultationDetailPostFailure(error));
      });
  };
};

export const fetchConsultationDetailPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchConsultationDetailPostSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchConsultationDetailPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
