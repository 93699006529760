import { FETCH_IMG_REQUEST, FETCH_IMG_SUCCESS, FETCH_IMG_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const documentGetImage = (request) => {
  return (dispatch) => {
    dispatch(fetchDocumentsGetImageRequest());
    const url =
      'second-opinion/v1/document/' + request.id + '/?category=ImagingStudies&limit=5&offset=' + request.offset;

    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let response = res.data.data;
        dispatch(fetchDocumentsGetImageSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDocumentsGetImageFailure(error));
      });
  };
};

export const fetchDocumentsGetImageRequest = () => {
  return {
    type: FETCH_IMG_REQUEST,
  };
};

export const fetchDocumentsGetImageSuccess = (result) => {
  return {
    type: FETCH_IMG_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsGetImageFailure = (error) => {
  return {
    type: FETCH_IMG_FAILURE,
    payload: error,
  };
};
