import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const fetchStaticeData = (token) => {
  const url = 'utils/v1/pre-populated-data/';

  return (dispatch) => {
    dispatch(fetchStaticDataRequest());
    authRequest(url, 'GET', null, token)
      .then((res) => {
        let response = res.data;
        dispatch(fetchStaticDataSuccess(response));
      })

      .catch((error) => {
        console.error(error);
        dispatch(fetchStaticDataFailure(error));
      });
  };
};

export const fetchStaticDataRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchStaticDataSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchStaticDataFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
