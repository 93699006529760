import { FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const messagePost = (request) => {
  return (dispatch) => {
    dispatch(fetchMessagePostRequest());
    const url = 'second-opinion/v1/opinion/';
    authRequest(url, 'POST', request, request.token)
      .then((res) => {
        dispatch(fetchMessagePostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchMessagePostFailure(error));
      });
  };
};

export const fetchMessagePostRequest = () => {
  return {
    type: FETCH_MESSAGES_REQUEST,
  };
};

export const fetchMessagePostSuccess = (result) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: result,
  };
};

export const fetchMessagePostFailure = (error) => {
  return {
    type: FETCH_MESSAGES_FAILURE,
    payload: error,
  };
};
