import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';
import { getFullName } from '../../../utilities/helperFunctions';

export const fetchPhysician = (data) => {
  let subUrl = 'user/v1/profile/?limit=1000';
  if (data.value) {
    subUrl = 'user/v1/profile/?limit=1000&specialty=' + data.value;
  }
  const url = subUrl;
  return (dispatch) => {
    dispatch(fetchPhysicianRequest());
    authRequest(url, 'GET', null, data.token)
      .then((res) => {
        let response = res.data.data;
        let tempSpecializations = [];
        for (const element of response.results) {
          let tempboards = [];
          if (element.specialisation !== undefined) {
            tempboards = element.specialisation;
          }

          let tempDegree = [];
          if (element.education_details !== undefined) {
            element.education_details.forEach((degree) => {
              tempDegree.push(degree.degree);
            });
          }
          let currentInstitutes = [];
          if (element.training_institutes !== undefined) {
            element.training_institutes.forEach((current) => {
              if (current.type === 'Current') {
                currentInstitutes.push(current.institute);
              }
            });
          }
          let id = null;
          if (element.id) {
            id = element.id;
          } else if (element.physician_detail && element.physician_detail.physician) {
            id = element.physician_detail.physician;
          }
          const currentPhycisian = {
            id: id,
            name: getFullName(element),
            picture: element.picture,
            board: tempboards,
            speciality: element.physician_detail ? element.physician_detail.specialty_text : '',
            degree: element.education_details ? tempDegree : ['None'],
            practice: element.physician_detail ? element.physician_detail.area_of_practice : ['None'],
            degreeTitle: element.physician_detail ? element.physician_detail.degree : '',
            institutes: element.training_institutes ? currentInstitutes : ['None'],
            specialisation: element.specialisation,
          };
          tempSpecializations.push(currentPhycisian);
        }
        dispatch(fetchPhysicianSuccess(tempSpecializations));
      })
      .catch((error) => {
        dispatch(fetchPhysicianFailure(error.message));
      });
  };
};

export const fetchPhysicianRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchPhysicianSuccess = (list) => {
  return {
    type: FETCH_SUCCESS,
    payload: list,
  };
};

export const fetchPhysicianFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
