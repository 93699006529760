import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

const url = 'second-opinion/v1/request-consultation/';
export const consultationPost = (request) => {
  return (dispatch) => {
    dispatch(fetchConsultationPostRequest());
    authRequest(url, 'POST', request.data, request.token)
      .then((res) => {
        dispatch(fetchConsultationPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchConsultationPostFailure(error.message));
      });
  };
};

export const fetchConsultationPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchConsultationPostSuccess = (consultation) => {
  return {
    type: FETCH_SUCCESS,
    payload: consultation,
  };
};

export const fetchConsultationPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
