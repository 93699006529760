export const dashboardRoutes = [
  {
    path: '/viewmedicalprofile',
    name: 'Medical Profile',
    layout: '/admin',
  },
  {
    path: '/previousrecords',
    name: 'Previous Medical Records',
    layout: '/admin',
  },
  {
    path: '/yourrequests',
    name: 'Request Consultation',
    layout: '/admin',
  },
];

export const physicianRoutes = [
  {
    path: '/unsubmittedopinionrequest',
    name: 'Opinion Requests',
    layout: '/admin',
  },
  {
    path: '/submittedopinionrequest',
    name: 'Submitted Opinions',
    layout: '/admin',
  },
  /*{
    path: '/previousrecords',
    name: 'Medical Records',
    layout: '/admin',
  },*/
];

export const adminRoutes = [
  {
    path: '/profilerequests',
    name: 'Account Requests',
    layout: '/admin',
  },
  {
    path: '/add',
    name: 'Add Medical Data',
    layout: '/admin',
  },
  {
    path: '/selectphysician',
    name: 'Approved Physicians',
    layout: '/admin',
  },
  {
    path: '/createaccount',
    name: 'Create Accounts',
    layout: '/admin',
  },
  {
    path: '/invite',
    name: 'Invite Physicians',
    layout: '/admin',
  },
];

export const monthDays = {
  '01': 31,
  '02': 28,
  '03': 31,
  '04': 30,
  '05': 31,
  '06': 30,
  '07': 31,
  '08': 31,
  '09': 30,
  10: 31,
  11: 30,
  12: 31,
};

export const errorMessages = {
  missingMedicalProfile: 'Could not fetch medical profile. Error: User has no medicalprofile.',
};

export const medicalConditions = [
  { id: 'Diabetes' },
  { id: 'Hypertension' },
  { id: 'Congestive Heart Failure' },
  { id: 'COPD- Chronic obstructive pulmonary disease' },
  { id: 'Stroke' },
  { id: 'Asthma' },
  { id: 'Tuberculosis' },
  { id: 'Pneumonia' },
  { id: 'COVID19' },
  { id: 'Dialysis' },
];
export const immunizations = [
  { id: 'Pneumococcal polysaccharide (PPSV 23)' },
  { id: 'Influenza last fall' },
  { id: 'Pneumococcal conjugate(PCV 13)' },
  { id: 'Hepatitis A' },
  { id: 'Shingles' },
  { id: 'Polio' },
  { id: 'MMR (Measles/Mumps/Rubella)' },
  { id: 'Meningococcal' },
  { id: 'BCG' },
  { id: 'Tdap (Tetanus, diphtheria, pertussis)' },
];

export const surgeries = [
  { id: 'CABG - Coronary Artery Bypass' },
  { id: 'Hysterectomy' },
  { id: 'Cholecystectomy - Gall bladder removal' },
  { id: 'Mastectomy' },
  { id: 'Cancer surgery' },
];

export const marks = [
  {
    value: 1,
    label: 'Little or No',
  },
  {
    value: 2,
    label: '1-2',
  },
  {
    value: 3,
    label: '3-4',
  },
  {
    value: 4,
    label: '4-6',
  },
  {
    value: 5,
    label: '6-7',
  },
  {
    value: 6,
    label: '7+',
  },
];

export const letterMapping = {
  1: 'B',
  2: 'C',
  3: 'D',
  4: 'E',
  5: 'F',
  6: 'G',
  7: 'H',
  8: 'I',
  9: 'J',
  10: 'K',
  11: 'L',
  12: 'M',
  13: 'N',
};

export const heightConstant = 3.281;
export const weightConstant = 2.205;
export const meter = 'm';
export const kilogram = 'kg';
export const pound = 'pounds';
export const foot = 'feet';
export const centimeter = 'cm';
export const inches = 'in';
export const inchToCm = 2.54;
export const emailerror = 'Account with the given email already exists';
export const bulkaccountserror = 'Creating more than 15 accounts in a single request is not allowed';
export const numberValidator = /^[1-9]\d*(\.\d+)?$/;
export const numeberWithSlashValidator = /^[1-9]\d*(\.\d+)*(\/\d+)?$/;
export const nameValidator = /^[A-Za-z .]+$/;
export const fullNameValidator = /^[a-zA-Z]+(?:[\s.]+[a-zA-Z]+)*$/;
export const emailValidator = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

export const consultationPrice = 350;
