/**

 * Get current user data

 * @param state

 * @return {any}

 */

export const getUser = (state) => state.user;
