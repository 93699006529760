import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

const url = 'second-opinion/v1/medical-profile/';
export const medicalPost = (request) => {
  return (dispatch) => {
    dispatch(fetchMedicalPostRequest());
    authRequest(url, 'POST', request.data, request.token)
      .then((res) => {
        dispatch(fetchMedicalPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchMedicalPostFailure(error.message));
      });
  };
};

export const fetchMedicalPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchMedicalPostSuccess = (consultation) => {
  return {
    type: FETCH_SUCCESS,
    payload: consultation,
  };
};

export const fetchMedicalPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
