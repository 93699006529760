import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import Carousel from './Carousel';
import Asset5_1 from '../../resources/asset5-1.webp';
import Asset5_2 from '../../resources/asset5-2.webp';
import Asset5_3 from '../../resources/asset5-3.webp';
import readMoreIcon from '../../resources/read-more-icon.svg';
import '../../css/landingpage.css';

const NewsArticles = (props) => {
  const { mode } = props;
  const [currentCard, setCurrentCard] = useState(0);
  const Articles = [
    {
      title: 'A Research Anthology of Law and Policy Resources',
      img: Asset5_1,
      alt: 'research icon',
      link: 'https://www.cdc.gov/phlp/publications/topic/anthologies/anthologies-telehealth.html',
    },

    {
      title: 'Telehealth allows rural patients to see specialists',
      img: Asset5_2,
      alt: 'rural patient icon',
      link: 'https://www.liebertpub.com/doi/abs/10.1089/tmj.2017.0161',
    },

    {
      title: 'Telehealth is cost saving',
      img: Asset5_3,
      alt: 'research icon',
      link: 'https://journals.plos.org/plosone/article?id=10.1371/journal.pone.0170581',
    },
  ];

  const openLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <>
      {' '}
      {mode === 'mob' ? (
        <Carousel
          markup={
            <Box
              width='100%'
              display='flex'
              flexDirection='column'
              className='news-article-card shadow-interaction'
              borderRadius='20px'
              onClick={() => openLink(Articles[currentCard].link)}>
              <Box width='100%' height='65%' borderRadius='20px'>
                <img
                  alt='article'
                  src={Articles[currentCard].img}
                  style={{
                    borderRadius: '20px 20px 0 0',
                    height: '100%',
                    width: '100%',
                  }}
                  width={400}
                  height={250}
                />
              </Box>
              <Box height='25%' width='100%' display='flex' flexDirection='column' sx={{ p: 4 }}>
                <span className='article-card-heading'>{Articles[currentCard].title}</span>
                <span className='article-card-link'>Read More </span>
              </Box>
            </Box>
          }
          currentCard={currentCard}
          setCurrentCard={setCurrentCard}
          arrow={false}
          Reviews={Articles}></Carousel>
      ) : (
        <Box display='flex' flexDirection='row' justifyContent='space-between' sx={{ mb: 16 }}>
          {Articles.map((obj, ind) => (
            <Box
              width='32%'
              display='flex'
              flexDirection='column'
              className='news-article-card shadow-interaction'
              borderRadius='20px'
              onClick={() => openLink(obj.link)}>
              <Box width='100%' height='65%' borderRadius='20px'>
                <img
                  src={obj.img}
                  alt={obj.alt}
                  style={{
                    borderRadius: '20px 20px 0 0',
                  }}
                  width='100%'
                  height='100%'
                />
              </Box>
              <Box
                height='35%'
                width='100%'
                display='flex'
                flexDirection='column'
                sx={{ p: 4 }}
                justifyContent='space-between'>
                <span className='article-card-heading'>{obj.title}</span>
                <Box display='flex' flexDirection='row' alignItems='center'>
                  <span className='article-card-link'>Read More </span>
                  <img alt='Read more icon' src={readMoreIcon} className='read-more-icon' width={20} height={20} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};

export default NewsArticles;
