import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const documentGet = (request) => {
  return (dispatch) => {
    dispatch(fetchDocumentsGetRequest());
    const url =
      'second-opinion/v1/document/' +
      request.id +
      '/?category=' +
      request.category +
      '&limit=5&offset=' +
      request.offset;

    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let response = res.data.data;
        dispatch(fetchDocumentsGetSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDocumentsGetFailure(error));
      });
  };
};

export const fetchDocumentsGetRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDocumentsGetSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsGetFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
