import React from 'react';
import { Box } from '@material-ui/core';
import carouselArrowLeft from '../../resources/carousel-left-arrow.svg';
import carouselArrowRight from '../../resources/carousel-right-arrow.svg';
import '../../css/carousel.css';

const Carousel = (props) => {
  const { currentCard, setCurrentCard, markup, Reviews, arrow } = props;

  return (
    <Box width='100%' display='flex' flexDirection='column' justifyContent='space-between'>
      <Box width='100%' display='flex' flexDirection='row' justifyContent='space-between'>
        <Box width='5%'>
          {arrow && currentCard > 0 && (
            <Box
              height='100%'
              display='flex'
              alignItems='center'
              justifyContent='start'
              className='review-arrow'
              onClick={() => setCurrentCard(currentCard - 1)}>
              <img alt='carouselArrowLeft' src={carouselArrowLeft} width={50} height={50} />
            </Box>
          )}
        </Box>
        <Box width='90%'>{markup}</Box>
        <Box width='5%'>
          {arrow && currentCard < Reviews.length - 1 && (
            <Box
              height='100%'
              display='flex'
              alignItems='center'
              justifyContent='flex-end'
              className='review-arrow'
              onClick={() => setCurrentCard(currentCard + 1)}>
              <img alt='carouselArrowRight' src={carouselArrowRight} width={50} height={50} />
            </Box>
          )}
        </Box>
      </Box>
      <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' sx={{ mt: 4 }}>
        {Reviews.map((obj, ind) => (
          <Box
            className={`carousel-dot ${ind === currentCard && 'selected'}`}
            onClick={() => setCurrentCard(ind)}></Box>
        ))}
      </Box>
    </Box>
  );
};

export default Carousel;
