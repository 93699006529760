import { FETCH_SPECIALITIES_REQUEST, FETCH_SPECIALITIES_SUCCESS, FETCH_SPECIALITIES_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const getSpecialities = (isActive, token) => {
  return (dispatch) => {
    dispatch(fetchSpecialitiesGetRequest());
    let url = `user/v1/specialty`;

    if (isActive !== null) {
      url = `${url}?is_active=${isActive}`;
    }

    authRequest(url, 'GET', null, token)
      .then((res) => {
        dispatch(fetchSpecialitiesGetSuccess(res.data.data.results));
      })
      .catch((error) => {
        dispatch(fetchSpecialitiesGetFailure(error));
      });
  };
};

export const fetchSpecialitiesGetRequest = () => {
  return {
    type: FETCH_SPECIALITIES_REQUEST,
  };
};

export const fetchSpecialitiesGetSuccess = (result) => {
  return {
    type: FETCH_SPECIALITIES_SUCCESS,
    payload: result,
  };
};

export const fetchSpecialitiesGetFailure = (error) => {
  return {
    type: FETCH_SPECIALITIES_FAILURE,
    payload: error,
  };
};
