export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const FETCH_IMG_REQUEST = 'FETCH_IMG_REQUEST';
export const FETCH_IMG_SUCCESS = 'FETCH_IMG_SUCCESS';
export const FETCH_IMG_FAILURE = 'FETCH_IMG_FAILURE';

export const FETCH_LAB_REQUEST = 'FETCH_LAB_REQUEST';
export const FETCH_LAB_SUCCESS = 'FETCH_LAB_SUCCESS';
export const FETCH_LAB_FAILURE = 'FETCH_LAB_FAILURE';

export const FETCH_OTHER_REQUEST = 'FETCH_OTHER_REQUEST';
export const FETCH_OTHER_SUCCESS = 'FETCH_OTHER_SUCCESS';
export const FETCH_OTHER_FAILURE = 'FETCH_OTHER_FAILURE';

export const FETCH_SUBMITTED_REQUEST = 'FETCH_SUBMITTED_REQUEST';
export const FETCH_SUBMITTED_SUCCESS = 'FETCH_SUBMITTED_SUCCESS';
export const FETCH_SUBMITTED_FAILURE = 'FETCH_SUBMITTED_FAILURE';

export const FETCH_MESSAGES_REQUEST = 'FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_FAILURE = 'FETCH_MESSAGES_FAILURE';

export const FETCH_URL_REQUEST = 'FETCH_URL_REQUEST';
export const FETCH_URL_SUCCESS = 'FETCH_URL_SUCCESS';
export const FETCH_URL_FAILURE = 'FETCH_URL_FAILURE';

export const FETCH_SPECIALITIES_REQUEST = 'FETCH_SPECIALITIES_REQUEST';
export const FETCH_SPECIALITIES_SUCCESS = 'FETCH_SPECIALITIES_SUCCESS';
export const FETCH_SPECIALITIES_FAILURE = 'FETCH_SPECIALITIES_FAILURE';
