import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const fetchConsultation = (request) => {
  let side_url;

  side_url = 'second-opinion/v1/request-consultation/?limit=5&offset=' + request.offset;

  if (request.completed !== undefined && request.completed === true) {
    side_url = 'second-opinion/v1/request-consultation/?limit=5&offset=' + request.offset + '&completed=True';
  }

  const url = side_url;
  return (dispatch) => {
    dispatch(fetchConsultationRequest());
    authRequest(url, 'GET', null, request.token)
      .then((response) => {
        const consultation = response.data.data.results;
        let output = {
          consultation: consultation,
          count: response.data.data.count,
        };
        dispatch(fetchConsultationSuccess(output));
      })
      .catch((error) => {
        dispatch(fetchConsultationFailure(error.message));
      });
  };
};

export const fetchConsultationRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchConsultationSuccess = (consultation) => {
  return {
    type: FETCH_SUCCESS,
    payload: consultation,
  };
};

export const fetchConsultationFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
