import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const addConditions = (request) => {
  const url = 'utils/v1/add/pre-populated-data/';
  return (dispatch) => {
    dispatch(fetchAddConditionsRequest());
    authRequest(url, 'POST', request.data, request.token)
      .then((res) => {
        dispatch(fetchAddConditionsSuccess(res.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchAddConditionsFailure(error));
      });
  };
};

export const fetchAddConditionsRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchAddConditionsSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchAddConditionsFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
