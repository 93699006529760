import { FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE } from './constants';

const initialState = {
  messageLoading: false,
  messageResult: [],
  messageError: '',
};

const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MESSAGES_REQUEST:
      return {
        ...state,
        messageLoading: true,
      };
    case FETCH_MESSAGES_SUCCESS:
      return {
        messageLoading: false,
        messageResult: action.payload,
        messageError: '',
      };
    case FETCH_MESSAGES_FAILURE:
      return {
        messageLoading: false,
        messageResult: [],
        messageError: action.payload,
      };
    default:
      return state;
  }
};

export default messagesReducer;
