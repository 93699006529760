/**

 * Get current user data

 * @param state

 * @return {any}

 */
export const getIsLogged = (state) => state.isLogged;
