import { FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const notificationGet = (request) => {
  return (dispatch) => {
    dispatch(fetchNotificationGetRequest());
    const url = 'user/v1/notification/?limit=' + request.limit;
    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let count = 0;
        res.data.data.results.forEach((element) => {
          if (element.viewed === false) {
            count++;
          }
        });

        let response = {
          response: res.data.data.results,
          count: count,
          totalCount: res.data.data.count,
        };
        dispatch(fetchNotificationGetSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchNotificationGetFailure(error));
      });
  };
};

export const fetchNotificationGetRequest = () => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
};

export const fetchNotificationGetSuccess = (result) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: result,
  };
};

export const fetchNotificationGetFailure = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};
