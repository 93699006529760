import { FETCH_URL_REQUEST, FETCH_URL_SUCCESS, FETCH_URL_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const documentGetUrl = (request) => {
  return (dispatch) => {
    dispatch(fetchDocumentsGetUrlRequest());
    const url =
      'second-opinion/v1/document/' +
      request.id +
      '/document_url/?category=LaboratoryInvestigations&limit=5&offset=' +
      request.offset;

    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let response = res.data.data;
        dispatch(fetchDocumentsGetUrlSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDocumentsGetUrlFailure(error));
      });
  };
};

export const fetchDocumentsGetUrlRequest = () => {
  return {
    type: FETCH_URL_REQUEST,
  };
};

export const fetchDocumentsGetUrlSuccess = (result) => {
  return {
    type: FETCH_URL_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsGetUrlFailure = (error) => {
  return {
    type: FETCH_URL_FAILURE,
    payload: error,
  };
};
