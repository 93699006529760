/**

 * Get current user data

 * @param state

 * @return {any}

 */

export const getToken = (state) => state.token;
