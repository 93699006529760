import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const documentDelete = (request) => {
  return (dispatch) => {
    dispatch(fetchDocumentsDeleteRequest());
    const url = 'second-opinion/v1/document/' + request.id + '/';
    authRequest(url, 'DELETE', null, request.token)
      .then((res) => {
        let response = res.data;
        dispatch(fetchDocumentsDeleteSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDocumentsDeleteFailure(error));
      });
  };
};

export const fetchDocumentsDeleteRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDocumentsDeleteSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsDeleteFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
