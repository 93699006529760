import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

const url = 'second-opinion/v1/document/';
export const documentPost = (request) => {
  return (dispatch) => {
    dispatch(fetchDocumentsPostRequest());
    authRequest(url, 'POST', request.fd, request.token)
      .then((res) => {
        dispatch(fetchDocumentsPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchDocumentsPostFailure(error));
      });
  };
};

export const fetchDocumentsPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchDocumentsPostSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
