import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const fetchprepopulatedData = (token) => {
  const url = 'utils/v1/pre-populated-data/';

  return (dispatch) => {
    dispatch(fetchPrePopulatedRequest());
    authRequest(url, 'GET', null, token)
      .then((res) => {
        let response = res.data;
        let tempMedicineConditions = [];
        response.data.medical_conditions.forEach((element) => {
          tempMedicineConditions.push({ id: element.name, selected: true });
        });

        let tempImmunizations = [];

        response.data.immunizations.forEach((element) => {
          tempImmunizations.push({ id: element.name, selected: true });
        });

        let tempSurgeries = [];

        response.data.surgeries.forEach((element) => {
          tempSurgeries.push({ id: element.name, selected: true });
        });
        let temp_lifestyle_category = [];
        response.data.lifestyle_category.forEach((element) => {
          temp_lifestyle_category.push(element);
        });
        let data = {
          MedicineConditions: tempMedicineConditions,
          Immunizations: tempImmunizations,
          Surgeries: tempSurgeries,
          Lifestyle: temp_lifestyle_category,
        };
        dispatch(fetchPrePopulatedSuccess(data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchPrePopulatedFailure(error));
      });
  };
};

export const fetchPrePopulatedRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchPrePopulatedSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchPrePopulatedFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
