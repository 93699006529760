import React from 'react';
import Loader from 'react-loader-spinner';

import * as theme from '../colorConstants';

const { colorMain } = theme;

function Loading() {
  return (
    <div style={{ paddingTop: '120px' }}>
      <Loader
        type='Bars'
        color={colorMain}
        height={100}
        width={100}
        timeout={60000}
        style={{
          position: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      />
    </div>
  );
}

export default Loading;
