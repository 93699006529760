import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { supportData, resourcesData, footerHeading, socialMediaData } from '../content/footerData';

import locationIcon from '../resources/location-icon.svg';

import '../css/landingpage.css';
import { NavLink } from 'react-router-dom';

const LandingPageFooter = () => {
  const data = useSelector((state) => state.specialitiesReducer);
  const locationLink = 'https://www.google.com/maps/search/?api=1&query=5028+Neiman+Cove+Raleigh+NC+27612+USA';

  const openLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box
      display='flex'
      justifyContent='center'
      width='100%'
      flexDirection='column'
      alignItems='center'
      sx={{ mt: 8, bgcolor: '#E7EBF1' }}
      className='footer-container'>
      <Box
        width={{ xs: '95%', md: '83%' }}
        display='flex'
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent='space-between'
        gap={4}
        className='footer'>
        <Box display='flex' flexDirection='column' alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Box className='footer-title' component='div'>
            {footerHeading.HOME}
          </Box>
          <NavLink to='/' className='footer-link'>
            Home
          </NavLink>
        </Box>

        <Box display='flex' flexDirection='column' alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Box className='footer-title' component='div'>
            {footerHeading.SERVICES}
          </Box>
          {data.specialitiesResult.map((service) => (
            <NavLink to={`/${service.slug}`} className='footer-link' target='_blank' rel='noopener noreferrer'>
              {service.name}
            </NavLink>
          ))}
        </Box>

        <Box display='flex' flexDirection='column' alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Box className='footer-title' component='div'>
            {footerHeading.SUPPORT}
          </Box>
          {supportData.map((obj) => (
            <NavLink to={`${obj.link}`} className='footer-link'>
              {obj.name}
            </NavLink>
          ))}
        </Box>

        <Box display='flex' flexDirection='column' alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Box className='footer-title' component='div'>
            {footerHeading.RESOURCES}
          </Box>
          {resourcesData.map((obj) => (
            <NavLink to={`${obj.link}`} className='footer-link'>
              {obj.name}
            </NavLink>
          ))}
        </Box>

        <Box display='flex' flexDirection='column' alignItems={{ xs: 'center', md: 'flex-start' }}>
          <Box className='footer-title' component='div'>
            {footerHeading.CONNECT_WITH_US}
          </Box>
          <Box display='flex' flexDirection='column' alignItems={{ xs: 'center', md: 'flex-start' }} gap={2}>
            <NavLink to={`/`} className='footer-link'>
              About Us
            </NavLink>
            <Box>
              Phone: <strong>+1 (617) 319-3568</strong>
            </Box>
            <Box>
              Email: <strong>customerservice@ourexpertdoc.com</strong>
            </Box>
            <Box display='flex' justifyContent={{ xs: 'center', md: 'flex-start' }} gap={2} mt={1} mb={1}>
              {socialMediaData.map((obj) => (
                <img
                  src={obj.icon}
                  className='social-media-button'
                  onClick={() => openLink(obj.link)}
                  alt={obj.name}
                  width={25}
                  height={25}
                />
              ))}
            </Box>
            <Box></Box>
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='row'
        bgcolor='#5181d9'
        className='copyright-container'
        height={'24px'}></Box>

      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='row'
        alignItems='center'
        bgcolor='#5181d9'
        className='copyright-container'>
        <Box height='100%' display='flex' alignItems='center' className='copyright'>
          <Box display='flex' flexDirection='row'>
            <img src={locationIcon} alt='Location Icon' />
            <a href={locationLink} target='_blank' rel='noopener noreferrer' className='location-text'>
              5028 Neiman Cove, Raleigh, NC 27612 USA
            </a>
          </Box>

          <span>© Copyright 2021. All Rights Reserved.</span>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='row'
        bgcolor='#5181d9'
        className='copyright-container'
        height={'24px'}></Box>
    </Box>
  );
};

export default LandingPageFooter;
