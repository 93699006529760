import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';
export const PhysicianDescription = (data) => {
  const url = `user/v1/physician/detail/${data.id}`;
  return (dispatch) => {
    dispatch(fetchPhysicianDescriptionRequest());
    authRequest(url, 'GET', null, data.token)
      .then((res) => {
        dispatch(fetchPhysicianDescriptionSuccess(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchPhysicianDescriptionFailure(error.message));
      });
  };
};

export const fetchPhysicianDescriptionRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchPhysicianDescriptionSuccess = (profile) => {
  return {
    type: FETCH_SUCCESS,
    payload: profile,
  };
};

export const fetchPhysicianDescriptionFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
