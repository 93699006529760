import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const fetchProfileUpdate = (data) => {
  const url = 'user/v1/profile/2/';

  return (dispatch) => {
    dispatch(fetchProfileUpdateRequest());
    authRequest(url, 'PATCH', data.data, data.token)
      .then((response) => {
        const result = response.data;
        dispatch(fetchProfileUpdateSuccess(result));
      })
      .catch((error) => {
        dispatch(fetchProfileUpdateFailure(error.message));
      });
  };
};

export const fetchProfileUpdateRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchProfileUpdateSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchProfileUpdateFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
