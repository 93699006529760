import { FETCH_NOTIFICATIONS_REQUEST, FETCH_NOTIFICATIONS_SUCCESS, FETCH_NOTIFICATIONS_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const notificationPost = (request) => {
  return (dispatch) => {
    dispatch(fetchNotificationPostRequest());
    const url = 'user/v1/notification/' + request.id + '/';
    authRequest(url, 'PATCH', request.data, request.token)
      .then((res) => {
        dispatch(fetchNotificationPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchNotificationPostFailure(error));
      });
  };
};

export const fetchNotificationPostRequest = () => {
  return {
    type: FETCH_NOTIFICATIONS_REQUEST,
  };
};

export const fetchNotificationPostSuccess = (result) => {
  return {
    type: FETCH_NOTIFICATIONS_SUCCESS,
    payload: result,
  };
};

export const fetchNotificationPostFailure = (error) => {
  return {
    type: FETCH_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};
