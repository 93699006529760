import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

const url = 'second-opinion/v1/medical-profile/1/';
export const medicalPatch = (request) => {
  return (dispatch) => {
    dispatch(fetchMedicalPatchRequest());
    authRequest(url, 'PATCH', request.data, request.token)
      .then((res) => {
        dispatch(fetchMedicalPatchSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchMedicalPatchFailure(error.message));
      });
  };
};

export const fetchMedicalPatchRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchMedicalPatchSuccess = (consultation) => {
  return {
    type: FETCH_SUCCESS,
    payload: consultation,
  };
};

export const fetchMedicalPatchFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
