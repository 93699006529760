import axios from 'axios';

export const authRequest = async (url, type, data, token) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const fullUrl = `${process.env.REACT_APP_BASE_URL_AWS}${url}`;
  const response = await axios({
    url: fullUrl,
    method: type.toLowerCase(),
    data: data,
    headers: token ? headers : null,
  });
  return response;
};
