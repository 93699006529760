import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

function fetchMedicalProfile(data) {
  const url = 'second-opinion/v1/medical-profile/2/';
  return (dispatch) => {
    authRequest(url, 'GET', null, data.token)
      .then((res) => {
        let profile = {
          response: res.data.data,
          tempMedicineConditions: data.tempMedicineConditions,
          tempImmunizations: data.tempImmunizations,
          tempSurgeries: data.tempSurgeries,
          temp_lifestyle_category: data.temp_lifestyle_category,
        };
        dispatch(fetchMedicalProfileSuccess(profile));
      })
      .catch((error) => {
        dispatch(fetchMedicalProfileFailure(error.response.data.message));
      });
  };
}

export const fetchStaticMedicalProfileData = (token) => {
  const url = 'utils/v1/pre-populated-data/';

  return (dispatch) => {
    dispatch(fetchMedicalProfileRequest());
    authRequest(url, 'GET', null, token)
      .then((res) => {
        let response = res.data;
        let tempMedicineConditions = [];
        response.data.medical_conditions.forEach((element) => {
          tempMedicineConditions.push(element);
        });

        let tempImmunizations = [];

        response.data.immunizations.forEach((element) => {
          tempImmunizations.push(element);
        });

        let tempSurgeries = [];

        response.data.surgeries.forEach((element) => {
          tempSurgeries.push(element);
        });
        let temp_lifestyle_category = [];
        response.data.lifestyle_category.forEach((element) => {
          temp_lifestyle_category.push(element);
        });
        let data = {
          tempMedicineConditions: tempMedicineConditions,
          tempImmunizations: tempImmunizations,
          tempSurgeries: tempSurgeries,
          temp_lifestyle_category: temp_lifestyle_category,
          token: token,
        };
        dispatch(fetchMedicalProfile(data));
      })

      .catch((error) => {
        dispatch(fetchMedicalProfileFailure(error));
      });
  };
};

export const fetchMedicalProfileRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchMedicalProfileSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchMedicalProfileFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
