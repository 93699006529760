import { FETCH_MESSAGES_REQUEST, FETCH_MESSAGES_SUCCESS, FETCH_MESSAGES_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const messageGet = (request) => {
  return (dispatch) => {
    dispatch(fetchMessageGetRequest());
    const url = 'second-opinion/v1/opinion/' + request.request_consultation + '/';
    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        dispatch(fetchMessageGetSuccess(res));
      })
      .catch((error) => {
        dispatch(fetchMessageGetFailure(error));
      });
  };
};

export const fetchMessageGetRequest = () => {
  return {
    type: FETCH_MESSAGES_REQUEST,
  };
};

export const fetchMessageGetSuccess = (result) => {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: result,
  };
};

export const fetchMessageGetFailure = (error) => {
  return {
    type: FETCH_MESSAGES_FAILURE,
    payload: error,
  };
};
