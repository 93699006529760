import axios from 'axios';
import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const invitePost = (request) => {
  const url = 'user/v1/profile-invite/' + request.id + '/';
  return (dispatch) => {
    dispatch(fetchAccountPostRequest());
    const requestData = {
      invite_status: request.status,
    };
    axios.patch(url, requestData, {
      headers: {
        Authorization: `Bearer ${request.token}`,
      },
    });
    authRequest(url, 'PATCH', requestData, request.token)
      .then((res) => {
        dispatch(fetchAccountPostSuccess(res.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchAccountPostFailure(error));
      });
  };
};

export const fetchAccountPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchAccountPostSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchAccountPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
