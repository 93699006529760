import { FETCH_LAB_REQUEST, FETCH_LAB_SUCCESS, FETCH_LAB_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const documentGetLab = (request, download) => {
  return (dispatch) => {
    dispatch(fetchDocumentsGetLabRequest());
    const url =
      'second-opinion/v1/document/' +
      request.id +
      '/?category=LaboratoryInvestigations&limit=5&offset=' +
      request.offset;
    // const downloadUrl =
    // 'second-opinion/v1/document/' + request.id + '/document_url/?category=LaboratoryInvestigations&limit=5&offset=' + request.offset;
    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let response = res.data.data;
        dispatch(fetchDocumentsGetLabSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDocumentsGetLabFailure(error));
      });
  };
};

export const fetchDocumentsGetLabRequest = () => {
  return {
    type: FETCH_LAB_REQUEST,
  };
};

export const fetchDocumentsGetLabSuccess = (result) => {
  return {
    type: FETCH_LAB_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsGetLabFailure = (error) => {
  return {
    type: FETCH_LAB_FAILURE,
    payload: error,
  };
};
