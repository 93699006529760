import facebookIcon from '../resources/facebook-icon.svg';
import twitterIcon from '../resources/twitter-icon.svg';
import linkedinIcon from '../resources/linkedin-icon.svg';
import tiktokIcon from '../resources/tiktok-icon.svg';
import youtubeIcon from '../resources/youtube-icon.svg';

// TODO: Links are yet to be added when the client provides them.
export const supportData = [
  { name: 'Contact Us', link: '/contactus' },
  { name: 'Disclaimer', link: '/disclaimers' },
  { name: 'FAQs', link: '' },
  { name: 'Terms of Use', link: '/termsandcondition' },
  { name: 'Privacy Policy', link: '/privacypolicy' },
  { name: 'Refund Policy', link: '' },
];
export const resourcesData = [
  { name: 'Health Tips', link: '' },
  { name: 'Healthcare News', link: '' },
  { name: 'Vlogs', link: '' },
  { name: 'Blogs', link: '' },
  { name: 'Reviews By Pateints', link: '' },
];

export const footerHeading = {
  HOME: 'Home',
  SERVICES: 'Services',
  SUPPORT: 'Support',
  RESOURCES: 'Resources',
  CONNECT_WITH_US: 'Connect with Us',
};

export const socialMediaData = [
  { name: 'Facebook', link: 'https://www.facebook.com/ourexpertdoc1', icon: facebookIcon },
  { name: 'Twitter', link: 'https://twitter.com/ourexpertdoc', icon: twitterIcon },
  { name: 'LinkedIn', link: 'https://www.linkedin.com/in/ourexpertdoc/', icon: linkedinIcon },
  { name: 'Tiktok', link: 'https://www.tiktok.com/@ourexpertdoc', icon: tiktokIcon },
  { name: 'Youtube', link: 'https://www.youtube.com/@ourexpertdoc', icon: youtubeIcon },
];
