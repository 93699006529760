import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  overrides: {
    MuiInput: {
      input: {
        '&::placeholder': {
          color: 'gray',
        },
        color: '', // if you also want to change the color of the input, this is the prop you'd use
      },
    },
  },
});

export default theme;
