import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const accountGet = (request) => (dispatch) => {
  dispatch(fetchAccountGetRequest());
  const url = `user/v1/profile-request/?limit=50&offset=${request.offset}`;

  authRequest(url, 'GET', null, request.token)
    .then((res) => {
      const response = res.data.data;
      const accounts = response.results;
      const output = { accounts, count: response.count };
      dispatch(fetchAccountGetSuccess(output));
    })
    .catch((error) => {
      dispatch(fetchAccountGetFailure(error));
    });
};

export const fetchAccountGetRequest = () => ({
  type: FETCH_REQUEST,
});

export const fetchAccountGetSuccess = (result) => ({
  type: FETCH_SUCCESS,
  payload: result,
});

export const fetchAccountGetFailure = (error) => ({
  type: FETCH_FAILURE,
  payload: error,
});
