export const font = 'geomanistregular'; // --font
export const colorMain = '#458FF6';
export const colorA = '#458FF6'; // --background-cl
export const colorMainLigher = '#45c1f6';
export const colorMainHover = '#45a6f6';
export const colorSecondaryText = 'white';
export const colorBText = 'white';
export const colorSecondaryBackground = 'white';
export const colorTextDarkGrey = '#505565';
export const dottedBackground = '#FAFAFC';
export const accepted = '#C5FFD1';
export const rejected = '#FFD5D8';
