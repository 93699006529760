import * as storage from '../../utilities/localStorageUtility';

const AccessTokenReducer = (state = storage.getAccessToken(), action) => {
  switch (action.type) {
    case 'UPDATE_ACCESS_TOKEN':
      state = action?.payload;
      return state;
    default:
      return state;
  }
};

export default AccessTokenReducer;
