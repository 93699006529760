/**

 * Get current user data

 * @param state

 * @return {any}

 */

export const getAccessToken = (state) => state.accessToken;
