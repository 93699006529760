import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';
import { setUser } from '../../../utilities/localStorageUtility';

const url = 'user/v1/profile/2/';
export const ProfileGet = (token) => {
  return (dispatch) => {
    dispatch(fetchProfileGetRequest());
    authRequest(url, 'GET', null, token)
      .then((res) => {
        dispatch(fetchProfileGetSuccess(res.data.data));
        /**save user data in local whenever this API is called */
        setUser(JSON.stringify(res.data.data));
      })
      .catch((error) => {
        dispatch(fetchProfileGetFailure(error.message));
      });
  };
};

export const fetchProfileGetRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchProfileGetSuccess = (consultation) => {
  return {
    type: FETCH_SUCCESS,
    payload: consultation,
  };
};

export const fetchProfileGetFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
