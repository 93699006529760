import { FaWhatsapp } from 'react-icons/fa';
import '../css/whatsappicon.css';
import React from 'react';
import { Tooltip } from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const CustomTooltip = withStyles({
    tooltip: {
      fontSize: '16px',
    },
  })(Tooltip);

const WhatsAppIcon = () => {
  return (
    <CustomTooltip title="Contact Us" arrow placement='left'>
      <div className='whatsapp-icon'>
        <a href='https://api.whatsapp.com/send?phone=16173193568' target='_blank' rel='noopener noreferrer'>
          <FaWhatsapp />
        </a>
      </div>
    </CustomTooltip>
  );
};

export default WhatsAppIcon;
