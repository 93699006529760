import * as storage from '../../utilities/localStorageUtility';

const TokenReducer = (state = storage.getToken(), action) => {
  switch (action.type) {
    case 'UPDATE_TOKEN':
      state = action.payload;
      return state;
    default:
      return state;
  }
};

export default TokenReducer;
