import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

const url = 'user/v1/profile-request/';
export const ProfileDescriptionPost = (request) => {
  return (dispatch) => {
    dispatch(fetchProfileDescriptionPostRequest());
    authRequest(url, 'POST', request.data, request.token)
      .then((res) => {
        dispatch(fetchProfileDescriptionPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchProfileDescriptionPostFailure(error.message));
      });
  };
};

export const ProfileDescriptionPatch = (request) => {
  const patchUrl = 'user/v1/physician-detail/' + request.id + '/';
  return (dispatch) => {
    dispatch(fetchProfileDescriptionPatchRequest());
    authRequest(patchUrl, 'PATCH', request.data, request.token)
      .then((res) => {
        dispatch(fetchProfileDescriptionPatchSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchProfileDescriptionPatchFailure(error.message));
      });
  };
};

export const fetchProfileDescriptionPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchProfileDescriptionPostSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchProfileDescriptionPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};

export const fetchProfileDescriptionPatchRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchProfileDescriptionPatchSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchProfileDescriptionPatchFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
